import React, { useState } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import logo from "./systechnosoft_technologys_logo1.png";
import bgVideo from "./bgVideo.mp4";
import icon from "./text-to-speech.png";

const Speech = () => {
  const [value, setValue] = useState("");
  const { speak } = useSpeechSynthesis();
  return (
    <>
      <div className="container">
        <div className="navBar">
          <img src={logo} alt="logo" id="logo"/>
          <h4>Touch New Heights With Us</h4>
          <img src={icon} alt="text_to_speech_icon" id="icon"/>
        </div>
        <video className="videoTag" autoPlay loop muted>
          <source src={bgVideo} type="video/mp4" />
        </video>
        <h2 className="focus-in-contract-bck">TEXT TO SPEECH 🔊</h2>
        <textarea
          placeholder="Enter your text here.."
          rows="8"
          value={value}
          onChange={(event) => setValue(event.target.value)
          }
        />
        <button onClick={() => speak({ text: value })}>Speak !</button>
      </div>
    </>
  );
};
export default Speech;
